body p {
  font-family: 'Quicksand', sans-serif;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Aphrodite'; 
}

/* header */
.contact_part{
  display: flex;
  justify-content: end;
  padding-right: 10px;
}
.contact_part div{
  display: flex;
  height: 20px;
  margin-top: 5px;
}
.contact_part button{
  background-color: white;
  border-left: 0px;
  border-top: 0px;
  border-right: 1px solid;
  border-bottom: 0px;
  padding-right: 14px;
  padding-left: 14px;
  color: grey;
}
.phoneimg{
  padding: 3px;
  color: grey;
}


/* navpad*/
.nav_main{
  position: absolute;
  top: 0;
  z-index: 7;
  width: 100%;
}
.menu_icondiv{
  display: none;
}
.menuicon{
  width: 40px;
}
:root {
  --rad: .7rem;
  --dur: .3s;
  --color-dark: #2f2f2f;
  --color-light: #fff;
  --color-brand: #f4c2c2;
  --font-fam: 'Lato', sans-serif;
  --height: 3rem;
  --btn-width: 6rem;
  --bez: cubic-bezier(0, 0, 0.43, 1.49);
}
.navpad{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.logosec{
  width: 100%;
  display: flex;
  justify-content:center;
  /* background-color: #707070; */
}
.logosec div{
  margin: 10px;
}
.tridialogo{
  width: 150px;
}
.tridiafooterlogo{
  width: 15rem;
  cursor: pointer;
}
.logosec h2{
  margin-bottom: 0px;
  color: white;
} 
.logosec p{
  margin: 0;
  font-size: x-small;
}
.logo{
  width: 70px;
  border-radius: 30%;
}
.logosec1{
  display: none;
  justify-content:center;
  /* background-color: #707070; */
  margin: 20px;
}
.logosec1 h2{
  margin-bottom: 0px;
  color: white;
} 
.serach_bar{
  display: flex; 
  align-items: center; 
  justify-content: center;
}
.serach_bar label{
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.serach_bar button {
  outline: 1; 
  display: none;
  position: absolute;
  top: 2;
  right: 33.3rem;
  width: var(--btn-width);
  font-weight: bold;
  background: var(--color-brand);
  border-radius: 0 var(--rad) var(--rad) 0;
  height: var(--height);
  font-family: var(--font-fam);
  border: 0;
  color: var(--color-dark);
  font-size: 1.6rem;
  border: 2px solid;
  /* box-shadow: 1px 1px 3px 0px; */
}
.serach_bar input{
  height: var(--height);
  font-family: var(--font-fam);
  border: 2px solid;
  color: var(--color-dark);
  font-size: 1.6rem;
  /* box-shadow: 1px 1px 3px 0px; */
}
.serach_bar input[type="search"] {
  outline: 0; 
  width: 100%;
  background: var(--color-light);
  padding: 0 1.6rem;
  border-radius: var(--rad);
  appearance: none; 
  transition: all var(--dur) var(--bez);
  transition-property: width, border-radius;
  z-index: 1;
  position: relative;
}
.serach_bar input:not(:placeholder-shown) {
  border-right: 0px;
  border-radius: var(--rad) 0 0 var(--rad);
  width: calc(100% - var(--btn-width));
  + button {
    display: block;
  }
}

.cartsection{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 63px;
}
.cartsection div{
  cursor: pointer;
  display: flex;
  border-right: 1px solid;
}
.cartsection div:hover p,
.cartsection div:hover .navpad_icons,
.cartsection div:hover .navpad_icons2 {
  color: #f4c2c2;
}
.navpad_icons{
  width: 30px;
  height: 30px;
  margin-right:3px;
  margin-left: 10px;
}
.navpad_icons2{
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-left: 10px;
}
.cartsection p{
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
  width: 80px;
  font-size: small;
}

/* navpad menubar */
.nav_menubar{
  /* background-color:#707070;; */
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  
}
.nav_menubarsubdiv1{
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  align-items: baseline;
  /* margin-left: 10px;
  margin-right: 10px; */
}
.nav_menu_div{
  display: flex;
  gap:15px;
  height: 25px;
}
.nav_menu{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  z-index: 10;
}
.nav_menu p{
  margin-top: 10px;
  font-family: 'Begum Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #dddcdc;
}
.seachicon{
  display: none;
  width: 48px;
  color: #FFFFFF;
  margin-top: -15px;
}
.nav_menubarsubdiv1 svg {
  opacity: 0;
  transform: translateY(-10px); /* Move it slightly upwards */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Transition for smooth reveal */
}
.nav_menu:hover svg {
  opacity: 1;
  transform: translateY(0); /* Bring it back to the original position */
}
.offer_img{
  width: 113px;
  height: 90px;
}
.offer_div{
  position: absolute;
  top: -93px;
  right: 6px;
}
.dropdown-content{
  display: block;
  position: absolute;
  left: 14px;
  top: 49px;
  /* top: 100%; */
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
  height: 200px;
  width: 200px;
}
.dropdown-content2{
  display: block;
  position: absolute;
  right: 10px;
  top: 49px;
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
  height: 200px;
  width: 200px;
}
.nav_menu:hover .dropdown-content{
  opacity: 1;
  visibility: visible;
}
.nav_menu:hover .dropdown-content2{
  opacity: 1;
  visibility: visible;
}

.arrowup{
  position: absolute;
  top: -12px;
  left: 90px;
  color: white;
}
.arrowuprings{
  position: absolute;
  top: -12px;
  left: 30px;
  color: white;
}
.arrowupmore{
  position: absolute;
  top: -12px;
  right: 60px;
  color: white;
}
.btn-one {
  color: #FFF;
  transition: all 0.3s;
  position: relative;
}
.btn-one span {
  transition: all 0.3s;
}
.btn-one::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255,255,255,0.5);
  border-bottom-color: rgba(255,255,255,0.5);
  transform: scale(0.1, 1);
}
.btn-one:hover span {
  letter-spacing: 2px;
}
.btn-one:hover::before {
  opacity: 1; 
  transform: scale(1, 1); 
}
.btn-one::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255,255,255,0.1);
}
.btn-one:hover::after {
  opacity: 0; 
  transform: scale(0.1, 1);
}
.more_menu{
  display: block;
  position: absolute;
  right: -20px;
  top: 49px;
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
  height: fit-content;
  width: 1350px;
}
.nav_menu:hover .more_menu{
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.more_menu_ul{
  position: absolute; /* Position relative to .more_menu */
  top: 0; /* Position at the top of .more_menu */
  left: 0; /* Align to the left of .more_menu */
  z-index: 100; /* Ensure it appears above other elements within .more_menu */
  margin: 0;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  border-radius: 4px;
  width: 100%;
 
}
.more_menu_ul li {
  font-size: 16px; 
  list-style: none; 
  padding: 10px; 
  color: #000;
  border-bottom: 1px solid #ddd; 
}
.more_menu_ul li:last-child {
  border-bottom: none; 
}
   /* drawer */
.drawer-container{
  width: 250px;
  /* background-color: #707070; */
}
.drawer_main{
  margin-top: 30px;
}
.closeicon{
  width: 25px;
}
.drawer_header{
  display: flex;
  margin-top: 10px;
}
.drawer_head h5{
  cursor: pointer;
}
.drawer_header h4{
  margin-left: 30px;
  margin-top: 5px;
  font-family:'Begum Sans';
}
.drawer_head{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.drawer_head h5{
  width: 170px;
}
.drawer_content{
  display: none;
}
.drawer_contentshow{
  display: block;
}


/* /*header video  part / */
.home_main{
  display: flex;
  flex-direction: column;
}
.home_video{
  height: 100vh;
  width: 100%;
  object-fit: cover;
}
.home_image{
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 100vh;          
  width: 100%;            
  background-size: cover;  
  background-attachment: fixed;  
  background-position: top; 
  background-repeat: no-repeat;  
  z-index: 1;
  align-items: center;
  background-position-y: -220px;

}
.home_image_contentdiv{
  display: flex;
  flex-direction: column;
  color: white;
  margin-right: 80px;
  max-width: 500px;
}
.home_image_contentdiv p{
  font-size: 20px;
}
.home_image1_content span{
  margin-left: 1.2rem;
}
.home_image_button{
  background-color: gray;
  color: white;
  width: 16rem;
  border: 0px;
}

.arrowright{
  width: 3rem;;
}
.home_image_content1{
  display: flex;
  flex-direction: column;
}
.home_image1{
  display: flex;
  align-items: center;
  position: relative;
  height: 100vh;          
  width: 100%;           
  background-size: cover;  
  background-attachment: fixed;  
  background-position: center;   
  background-repeat: no-repeat;  
  z-index: 1;
  justify-content: center;
}
.home_image1_content{
  display: flex;
  justify-content: center;
  color: white;
  align-self: center;
  text-align: center;
}

/* .home_image img{
  background-size: cover;
  width: 100%;
} */
.vedio_container{
  width: 100%;
  height: fit-content;
  position: relative;
  z-index: -1;
  background: #FFFFFF;
  display: flex;
  flex-direction:row;
}
.vedio_container_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 780px;
  padding-left: 40px;
  /* height: 348px; */
}
.vedio_container_content h4{
  width: 586px;
  height: 90px;
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  line-height: 90px;
  /* identical to box height, or 100% */
  letter-spacing: 0.01em;

  /* accent */
  color: #E89BAA;
}
.vedio_container_content div{
  width: 700px;
  /* height: 228px; */
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  letter-spacing: -0.05em;
  color: #707070;
}
.vedio_container_image{
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 500px;
  height: 700px;
  background: #B0B0B0;
  flex: none;
  order: 1;
  align-self: stretch;
  /* flex-grow: 1; */
}
.vedio_container img{
  position: absolute;
  top: 200px;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  width: 50%;
}

/* product sections */

.section1{
  background-color: gray;
  /* height: 100vh; */
  /* margin-top: 50px; */
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.section1_content{
  height: 600px;
  width: 100%;
  background-color: #D9D9D9;
}
.section1_subsection{
  position: absolute;
  width: 100%;
  height: fit-content;
  top: -325px;  
  z-index: 3;
  display: flex;
  justify-content: center;
}
.section1_part2{
  position: relative;
  height: 500px;
}
.section1_subsection_container{
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px);
  width: 80%;;
}
.section1_subsection_blurdiv{
  padding: 55px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid #FFF;
}
.section1_subsection_blurdiv h1{
  width:90%;
  font-weight: 500;
  text-align: center;
  max-width: 770px;
}
.section1_subsection_blurdiv span{
  margin-left: 2.5rem;
}
.section1_subsection_contentdiv{
  display: flex;
}
.section1_subsection_image{
  flex: 1 1 33%;
}
.section1_subsection_contentdiv img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section2{
  width: 100%;
  background-color: #f8f9fa;
}

.section2_line{
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
}
.section2_line svg{
  color: gray;
}
.section2_line hr{
  border: 3px solid gray;
  width: 100%;
}
.section2_boarderimg{
  position: absolute;
  top: 50rem;
  height: 3.5rem;
  width: 44rem;
  width: 100%;
}
.section2_boarderimg2{
  position: absolute;
  top: 70rem;
  height: 3.5rem;
  width: 44rem;
  width: 100%;
}
.section2_contents{
  display: flex;
  flex-direction: column;
  gap:75px;
  width: 100%;;
}
.section2_content_container{
  position: relative;
  display: flex;
  justify-content: center;
}
.border_design{
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 90px;
  width: 100%;
  background-image: url(./Assets/contentimages/design.png);
  background-repeat: repeat;
}
.section2_contents_content{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  z-index: 2;
  height: 500px;
}
.section2_contents_content_text{
  display: flex;
  flex-direction: row;
  width: 50%;
  gap:15px;
}
.content_div{
  display: flex;
  flex-direction: column;
}
.section2_contents_content_text p{
  font-size: 20px;
}

.section2_contents_content_image{
  width: 45%;
  height: 100%;
}
.section2_contents_content_image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section2_boarderimg3{
  position: absolute;
  top: 90rem;
  height: 3.5rem;
  width: 44rem;
  width: 100%;
}
.diamondicon{
  color: gray;
  font-size: 20px;
}
.section2_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section2_header{
  width: 60%;
  color: gray;
  text-align: center;
}
.section2span1{
  margin-left: 2rem;
}
.section2span2{
  margin-left: 8rem;
}
.section2_horidontalline{
  height: 0.1rem;
  width: 100%;
  background-color:gray;
  margin-top: 2rem;
}
.section2content2{
  display: flex;
  justify-content: space-around;
  margin-top: 5rem;
  width: 51rem;
}

#collection_back_btn{
  left:-1%;
}
.home_iconbutton{
  width: 48px;
}
.collection_iconbtn{
  width: 48px;
  height: 4rem;
}
.home_iconbutton img{
  width: 100%;
}
.product_view{
  background-color: gray;
  padding: 120px 80px;
}
.collectionview_section1_main{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap:20px;
  position: relative;
  flex-direction: column;
  background-color: #707070;
}
.product_image_nav{
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  bottom: 10%;
}
.product_image_nav button{
  width:64px;
}

.collectionview_section1_submain{
  width: 100%;
  display: flex;
  flex-direction: column;
  aspect-ratio: 9 / 7;
  padding: 0 80px;
  margin-top: 7rem;
}


.collectionview_section1_sub1{
  width: 100%;
  height: 70%;
  display: flex;
}
.collectionview_section1_sub1 div{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.collectionview_section1_sub2{
  display: flex;
  justify-content: space-between;
  height: 30%;
  width: 100%;
  gap: 7px;
}
.collectionview_section1_sub2 div{
  width: 100%;
  height: 100%;
  padding-top: 0.4rem;
}
.collectionlargeimage{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.collectionsmallimage{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* CATEGORY */
.category_main{
  width: 100%;
  background-color: gray;
  padding-top: 8rem;
  color: white;
}
.category_section1{
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #707070;
  padding: 150px 120px;
  gap:30px;
  color: #FFF;
}
.categories{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:25px;
  width: 100%;
}
.category_item{
  display: flex;
  flex-direction: column;
  width: 30%;
  gap:15px;
  align-items: center;
}
.category_img{
  width: 100%;
  aspect-ratio: 4/5;
  object-fit: cover;
}



/* 
.category_section1_sub1main{
  display: flex;
  gap: 2rem;
}
.category_section1_sub1main div{
  flex-grow: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25rem;
  width: 10rem;
}
.category_section2{
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  padding-right: 3rem;
}
.category_section1_sub2main{
  display: flex;
  gap: 2rem;
  padding-top: 3rem;
}
.category_section1_sub2main div{
  flex-grow: 1;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25rem;
  width: 10rem;
}
.category_img{
  width: 100%;
  height: 100%;
} */

/* CATEGORY LEVEL 1 */



.categorylevel1_section1{
  background-color: #707070;
  display: flex;
  color: white;
  padding: 150px 100px;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: flex-start;
  gap:50px;
}

.Category_Name_div{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Category_Name_div select{
  padding: 0 15px;
}

.category_products{
  display: flex;
  width: 100%;
  gap: 2%;
  flex-wrap: wrap;
}
.category_products_product{
  flex: 1 1 23%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
.category_products_product img{
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4/5;
}



/* 
.categorylevel1_section1sub1main{
  display: flex;
  justify-content: space-between;
}
.categorylevel1_section1sub1main select{
  padding-left: 1rem;
  padding-right: 4rem;
  height: 2rem;
}
.categorylevel1_section1sub2main{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.categorylevel1_section1sub2main div{
  width: 18rem;
  height: 22rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.categorylevel1_section1sub2main img{
  height: 90%;
  width: 100%;
} */
/* CATEGORY LEVEL2 */
.categorylevel2_main{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5rem;
}
.categorylevel2_section1{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80%;
  gap: 3rem;
}
.categorylevel2_section1sub1{
  width: 32%;
  height: 96%;
  position: relative;
}
.categorylevel2_section1sub1 img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* position: relative;
  z-index: 1; */
}
.categorylevel2_section1sub2{
  width: 39%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.categorylevel2_section1sub2 p{
  padding-top: 1rem;
}
.categorylevel2iconbutton{
  position: absolute;
  z-index: 2;
  bottom: 140px;
  right: 10px;
  background-color: transparent;
  border: 0;
}
.categorylevel2icon{
  color: white;
  font-size: 42px;
}
/* CATEGORY LEVEL3 */
.category_level3_main{
  height: 100vh;
  width: 100%;
  background-color: #707070;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  gap:50px;
}
.category_closebtn{
  position: absolute !important;
  top: 25px;
  right: 25px;
  color: white;
}
.category_closebtn svg{
  color: white;
  font-size: 38px;
}
/* .category_level3_main button{
  position: absolute;
} */
.category_level3_subdivmain{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
  gap:20px;
}
.category_level3_sub1{  
  max-height: 85%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
}
.product_images_wrapper{
  display: flex;
  justify-content: center;
}
.category_level3_sub2{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: calc(15% - 20px);  
  gap: 15px;
}
.category_level3_sub1 img{
  width: 100%;
  height: fit-content;
  max-height: 100%;
  align-self: flex-end;
  object-fit: contain;
}
.category_level3_sub2 div{
  /* background-color: white; */
  /* height: 80%; */
  flex-grow: 1;
  gap: 1rem;
  margin-top: 1rem;
}
.category_level3_subimg{
  max-width: 100px;
  height: 100%;
  object-fit: contain;
}


.blog{
  background-color: gray;
  padding: 150px 100px;
  color: white;
  display: flex;
  flex-direction: column;
  gap:20px;
}
.blog_container{
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.blog_content_main{
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 500px;
}
.blog_img_container{
  width: 50%;
  height: 100%;
}
.blog_img_container img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.blog_content{
  width: 50%;
}

.blog1_img_container{
  width: 100%;
}
.bloglevel1_container{
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
}
.blog1_img_container img{
  width: 100%;
  min-height: 220px;
  object-fit: cover;
}
.blog_content_div{
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 90%;
  justify-content: center;
  gap:10px;
}
.blog_content_div p{
  font-size: 22px;
}
.blog_content_div h1{
  font-size: 50px;
  max-width: 1000px;
}
.section2content2 img{
  width: 20rem;
  height: 15rem;
  position: relative;
  z-index: 2;
}


.section2_horidontal_line2{
  width: 15rem;
  height: 0.2rem;
  background-color: gray;
}
.section2content3{
  margin-top: 5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5rem;
}
.section3{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:8px;
  padding-top: 150px;
  justify-content: center;
  align-items: center;
}
.section3 .section2_line{
  width: 40%;
}

.diamond_line{
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.section3 .section2_line h4{
  width: fit-content;
  padding: 0 20px;
  margin: 0;
}

.home_collection{
  width: 100%;
  height: fit-content;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;
}
.home_collection_bg{
  position: relative;
  width: 100%;
  object-fit: contain;
}

/* #home_collection1{
  background-image: url('./Assets/contentimages/home_collection1.png');
} */
/* #home_collection2{
  background-image: url('./Assets/contentimages/home_collection2.png');
}
#home_collection3{
  background-image: url('./Assets/contentimages/home_collection3.png');
}
#home_collection4{
  background-image: url('./Assets/contentimages/home_collection4.png');
}
#home_collection5{
  background-image: url('./Assets/contentimages/home_collection5.png');
} */

.home_collection_text{
  display: flex;
  flex-direction: column;
  color: #FFF;
  width:400px; 
  position: absolute;
  left: 5%;
}
.home_collection_text h4{
  font-size: 24px;
}
.home_collection_text p{
  font-size: 20px;
}
.home_collection_text button{
  width: fit-content;
  padding:5px 20px;
  border-radius: 3px;
  border: none;
}
.home_collection_text.text_right{
  right: 5%;
  left: auto;
}

.section5{
  display: flex;
  flex-direction: column;
  gap:35px;
  width: 100%;
  padding: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}

.section5_header{
  display: flex;
  justify-content: space-between;
  color:#FFF;
  gap:50px;
}
.section5_header h2{
  width: 550px;
}
.section5_header a{
  width: fit-content;
  height: fit-content;
  padding: 5px 15px;
  font-weight: bold;
  color: black;
  background-color: white;
  border-radius: 3px ;
}
.section5_header a:hover{
  text-decoration: none;
}
.section5_images{
  display: flex;
  justify-content: center;
  gap:10px;
  width: 100%;
}
.section5_image{
  flex: 1 1 20%;
}
.section5_image img{
  width: 100%;
}
.about_section5_certification{
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px); 
  border: 1px solid #FFF;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px;
  gap:30px;
  max-width: 1050px;
  border-radius: 3px;
}
.about_section5 .about_section5_certification .section2_line{
  width: 60%;
}
.about_section5 .about_section5_certification .section2_line h4{
  padding: 0 10%;
}
.about_section5_certification_contents{
  display: flex;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
  gap:50px;
}
.about_section5_certification_contents_content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  text-align: center;
}
.about_section5_certification_contents_content img{
  width: 100%;
}




.section6{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  padding: 100px;
}
.section6_content{
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px); 
  display: flex;
  flex-direction: column;
  padding: 75px;
  border: 2px solid #FFF;
  align-items: center;
  gap:30px;
  text-align: center;
  color: #FFF;
}
.section6_items{
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: space-between;
}
.section6_item{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  text-align: center;
  gap:10px;
} 
.icon_bg{
  background-color: rgb(13, 5, 36);
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_bg svg{
  width: 70%;
}
















/* .section3 div{
  margin-right: 7rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
} */
/* 
.section4{
  margin-top: 0.5rem;
  width: 100%;
  height: 25rem;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-y:-50rem ;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.section4 div{
  margin-left: 7rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
}
.section4 button{
 border: 0px;
 width: 18rem;
}

.section5{
  margin-top: 0.5rem;
  width: 100%;
  height: 25rem;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-position-y: -27rem;
}
.section5 div{
  margin-right: 7rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
}
.section5 button{
 border: 0px;
 width: 18rem;
}
.section6{
  margin-top: 0.5rem;
  width: 100%;
  height: 25rem;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.section6 div{
  margin-left: 7rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
}
.section6 button{
 border: 0px;
 width: 18rem;
} */
.section7{       
  width: 100%; 
  /* height: 90vh;            */
  background-size: cover;   
  background-position: center; 
  background-repeat: no-repeat;  
  z-index: 1;
  align-items: center;
  margin-top: 0.5rem;
 
}
.section7_maindiv{
  padding: 4rem;
  color: white;
  display: flex;
  flex-direction: column;
}
.section7_content2{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
}
.section7_content2 img{
  width: 18.6rem;
  height: 17rem;
}
.section7_content1{
  display: flex;
  justify-content: space-between;
}
.section7_content1 button{
  border: 0px;
  height: 2rem;
  background-color: white;
}
.section8{
  height: 90vh;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  background-size: cover;   
  background-position: center; 
  background-repeat: no-repeat;  
  z-index: 1;
  align-items: center;
  margin-top: 0.1rem;
}
.section8_maindiv{
  width: 70%;
  height: 24rem;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px); 
  justify-content: space-around;
}
.section8_content1{
  display: flex;
  justify-content: center;
}
.section8_content2{
  display: flex;
  justify-content: space-around;
}
.section8_content2_sub{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 14rem;
}
.section8_content2 img{
  width: 5rem;
}
.section8_content2_span1{
  padding-left: 1.5rem;
}
.section8_content2_span2{
  padding-left: 2.5rem;
}
.section8_content2_span3{
  padding-left: 4rem;
}
.section8_icondiv{
  display: flex;
  justify-content: center;
}
.section8_icondiv div{
 display: flex;
 justify-content: center;
 width: 6rem;
 height: 6rem;
 border-radius: 50%;
 align-items: center;
}
.menu_wrap{
  display: flex;
  justify-content: space-around;
}
.menu_item{
  transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.image_wrap{
  background: #FFF8F9;
  border-radius: 24px;
  box-shadow: 2px 2px 5px 0px rgba(221, 197, 197, 0.25);
  border: 0.3px solid rgba(221, 197, 197, 0.5);
  transition: all .5s ease;
  width: 150px;
}
.image_wrap .img1{
  max-width: 149px;
}
.menu_item h2{
  color: #BFB9BD;
  font-size: 14px;
  line-height: 16px;
  margin: 10px 0 0 0;
  text-align: center;
}

/* MEHTHA PROFILE */
.profile_main{
  position: relative;
  z-index: -1;
  height: fit-content;
}

.profile_pic_div{
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}
.profile_pic_div h1{
  font-weight: bold;
}
.profile_pic_div img{
width: 400px;
border-radius: 50%;
}
.para_div{
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.82222;
  width: 745px;
}
.logo_div{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.logo_div img{
  width: 450px;
}
.profilesection3{
  display: flex;
  justify-content: space-around;
}
.profilesection3 p{
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.82222;
  width: 600px;
}
.profilesection4{
  margin-top: 1rem;
}
.profilesection4 h3{
  display: flex;
  justify-content: center;
}
.profilesection4 h5{
  display: flex;
  justify-content: center;
}
.careicon{
  width: 140px;
  height: 140px;
}

/* FOOTER */
.footer_container{
  display: flex;
  flex-direction: column;
  background-color: #707070;
  align-items: center;
  color: white;
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  border-top: 2px solid;
}
.footer_main{
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: white;
  align-items: end;
  padding-bottom: 1rem;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}
.footer_content{
  display: flex;
  gap: 25px;
  align-items: center;
}
.footer_contact{
  display: flex;
  gap:8px;
  flex-direction: column;
}
.footer_contact a{
  color: white;
}
.footer_address{
  min-width:220px;
  max-width: 250px;
}
.footer_main p{
  margin: 0px;
}
.footer_header h4{
  height: 90px;
  font-family: 'Begum Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 90px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.footer_navdetails{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  /* width: 20rem; */
}
.footer_navdetails button{
  width: fit-content;
  background: none;
  border: none;
  color:white;
  padding: 0;
}
.footer_navdetails button:hover{
  text-decoration: underline;
}
.title h4{
  width: 79px;
  height: 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.02em;
}
.global_footer_links ul{
  padding-left: 0px;
  padding-top: 20px;
}
.global_footer_links li{
  list-style: none;
}
.global_footer_links p{
  padding: 0px;
  margin-bottom: 0.5rem;
}
.social_media_img_div{
  display: flex;
  gap: 30px;
  /* width: 250px; */
}
.title{
  display: flex;
  justify-content: space-between;
}
.title button{
  border: 0px;
  background-color: #707070;
  display: none;
  color: white;
}
.title h4{
  width: 79px;
  height: 25px;
  font-family: 'Begum Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.02em;
}
.arrowdown{
  transform: translateY(0) rotate(0deg);
  transition: all 0.2s ease-in-out;
  width: 15px;
}
.arrowdown.rotate {
  transform: translateY(0) rotate(180deg);
  transition: all 0.2s ease-in-out;
  width: 15px;
}
.socialmedia_icons{
  width: 60px;
  height: 60px;
}
/* .facebookicon{
  font-size: 30px;
   margin-top: 10px;
   color: white;
}
.linkedinicon{
  font-size: 48px;
  margin-top: 8px;
}
.youtube_icon{
  width: 80px;
  height: 80px;
  margin-top: 5px;
  margin-left: 8px;
} */
.social_media_div{
  color: white;
  width: 240px;
  display: flex;
  flex-direction: row;
  gap:7%;
  justify-content: center;
}
.social_media_div a{
  width: 20%;
}
.footer_first_horizontal_line{
  margin-top: 1rem;
  height: 0.2rem;
  width: 100%;
  background-color: white;
}
.footer_second_horizontal_line{
  height: 0.1rem;
  width: 100%;
  background-color: white;
}
.footer_copyrightdiv{
  margin-top: 2rem;
  text-align: center;
}


/* ABOUT PAGE */
.about_section1_main{
  /* position: relative; */
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: white;
  align-items: center;
  background: linear-gradient(120deg, #2f2f2f, #4a4a4a);
}
.about_section1{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: flex-end;
  position: relative;
}
.about_bg_div{
  height: 100%;
  width: 50%;
}
.about_bg_img{
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.about_bg_div::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 35%;
  border-top: 5px solid #FFF;
}
.aboutsection_absoluteimagediv{
  width: fit-content;
  height: fit-content;
}
.section2_image{
  position: absolute;
  right: 0;
  top: -245px;
  width: 100%;
  height: calc(100% + 245px);
  z-index: 2;
}
.section2_image::before {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  height: 245px;
  border-left: 5px solid #FFF;
}
.section2_image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  border-top: 5px solid #FFF;
}
#diamond1{
  position: absolute;
  top: -255px;
  z-index: 3;
}
#diamond2{
  position: absolute;
  z-index: 3;
  top:-20px;
}
#diamond1, #diamond2{
  left:-15.5px;
}


/* .about_section1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 30%; 
  transform: translateX(-50%); 
  width: 37rem; 
  height: 0.2rem; 
  background-color: white
} */

.section2_image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .section2_image::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0%;  
  height: 30%;  
  width: 0.2rem;  
  background-color: white;
}
.section2_image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0%;  
  width: 50%;  
  height: 0.2rem;
  background-color: white;
} */
.about_section1_horizontalline{
  position: absolute;
  width: 22rem;
  height: 0.2rem;
  background-color: white;
  top: 37.73rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 18rem;
}
.about_section1_horizontalline2{
  position: absolute;
  width: 22rem;
  height: 0.2rem;
  background-color: white;
  top: 24.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  left: 39rem;
}
.about_section1_verticalline{
  position: absolute;
  width: 0.2rem;
  height: 35vh;
  background-color: white;
  top: 23.73rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 39rem;
  z-index: 4;
}
.header_diamond{
  position: relative;
  z-index: 4;
  color: white;
  font-size: 1.8rem;
  margin-left: -6px;
}
.about_section1_content_maindiv{
  padding-right: 5%;
  width: 48%;
  padding-bottom: 265px;
}
.about_section1_content_maindiv p{
  font-size: 22px;
  line-height: 2;
  margin: 0;
}
.about_section1_content1{
  display: flex;
  flex-direction: column;
  width: 546.5px;
  height: 351px;
}
.about_section1_content1 h4{
  height: 65px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  line-height: 65px;
  letter-spacing: -0.01em;
  color: #E89BAA;
}
.about_section1_content1 p{
  font-family: 'Begum Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  letter-spacing: -0.05em;
  color: #707070;
}
.about_section1_content2{
  width: 546.5px;
  height: 504px;
  background: #B0B0B0;
}
.about_section2{
  height: 500px;
  background: #707070;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border-bottom: 8rem solid; 
  border-image-source: url('../src/Assets/contentimages/design.png'); 
  border-image-slice: 100; 
  border-image-repeat:round;  */
  color: white;
  position: relative;
}
.about_section2_content{
  width:45%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap:15px;
}
.section2_wrapper{
  width: 50%;
  height: 100%;
  position: relative;
}
.about_section2_item{
  display: flex;
  gap: 15px;
  flex-direction: row;
}
.about_section2_item h2{
  width: fit-content;
  margin: 0;
}





.about_section2_content h4{
  display: flex;
  justify-content: center;
  height: 56px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 56px;
  letter-spacing: 0.01em;
  color: #E89BAA;
}
/* .about_section2_content div{
  width: 800px;
  margin:30px;
} */
.about_section2_content p{
  font-family: 'Begum Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #F7C6D0;
}
.about_section3{
  width: 100%;
  position: relative;
  /* display: flex;
  justify-content: flex-end;
  align-items: center; */
  /* background-image: url('../src/Assets/ourstoryimages/MaskGroup69.png');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover; */
  color: white;
  /* padding: 100px; */
}
.about_section3 img{
  width: 100%;
  /* height: 90vh; */
}
/* .about_section3_contentmaindiv{
  margin-right: 2rem;
} */
.about_section3_contentmaindiv{
  width: 500px;
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about_section3_contentmaindiv p{
  font-size: 20px;
  line-height: 1.7;
}
.about_section3_content1{
  width: 600px;
  height: 504px;
  background: #B0B0B0;
}
.about_section3_content2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 600px;
  height: 504px;
  background-color: #E0E0E0;
}
.about_section3_content2 div{
  margin: 20px;
}
.about_section3_content2 h4{
  font-family: 'Berkshire Swash';
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
  line-height: 70px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #E89BAA;
}
.about_section3_content2 p{
  font-family:'Begum Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  letter-spacing: -0.05em;
  color: #707070;
}
.about_section4{
  width: 100%;
  background-color: white;
  position: relative;
  padding: 100px 0;
}
.about_section4_contentmaindiv{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-direction: column;
  align-items: center;
  color: gray;
  gap: 75px;
}
.about_section4_headdiv{
  display: flex;
  align-items: center;
  width: 60%;
}
.about_section4_headdiv .section2_line{
  width: 100%;
}
.about_section4_headdiv h4{
  padding: 0 25px;
  width: fit-content;
}
.section4design_div {
    position: absolute;
    background-repeat: repeat;
    height: 4rem;
    width: 100%;
    top: 5rem;
}


.about_section4_content1{
 width: 100%;
 display: flex;
 justify-content: center;
}
.about_section4_content1 h4{
  margin: 0px;
}
.about_section4_content1 p{
  height: 190px;
  font-family: 'Begum Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  letter-spacing: -0.05em;
  color: #F7C6D0;
}
.about_section4_content2{
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.about_section4_content{
  display: flex;
  gap: 50px;
  justify-content: center;
  width: 90%;
}
.about_section4_content2 .border_design{
  bottom: auto;
  top: 0;
}
.about_section4_content2image{
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}
.about_section4_imagediv{
  background-color: rgb(187, 184, 184);
  position: relative;
  z-index: 3;
  box-shadow: -20px 19px 9px 0px rgb(216, 212, 212);
}

.about_section4_imagediv img{
  width: 300px;
  position: relative;
  z-index: 4;
  padding: 1rem;
}
.about_section4_content2text{
  padding-top: 150px;
  width:650px;
}
.about_section4_content2text p{
  font-size: 20px;
  font-weight: bold;
}
.about_section5{
  width: 100%;
  background-image: url('../src/Assets/contentimages/footerbackground2.png');
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  padding: 50px 75px;
  gap: 75px;
}
.about_section5 .section2_line{
  width: 50%;
}
.about_section5 .section2_line h4{
  padding: 0 100px;
}
.about_section5 svg{
  color:#FFF;
}
.about_section5 hr{
  border-color: #FFF;
}
.about_section5_contents{
  display: flex;
  width: 80%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 75px;
}
.about_section5_contents_content{
  display: flex;
  flex-direction: column;
  width: 200px;
  text-align: center;
  gap: 10px;
  align-items: center;
}
.about_section5_contents_content h4{
  padding: 0;
}





/* 
.about_section5_contentmain{
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 70%;
  justify-content: space-around;
}
.about_section5_header{
  display: flex;
  justify-content: center;
}
.about_section5_headersub{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 30rem;
}
.section5_hl{
  height: 0.2rem;
  width: 5rem;
  background-color: white;
}
.about_section5_content{
  display: flex;
  justify-content: space-around;
}
.about_section5_content_sub{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about_section5_content_sub h4{
  margin-top: 1.5rem;
}
.about_section5_content_sub img{
 width: 7rem;
 height: 7rem;
} 
.about_section5_span1{
  margin-left: 2rem;
}
.about_section5_span2{
  margin-left: 1.2rem;
} */
/* 
.about_section6{
  height: 80vh;
  background-image: url('../src/Assets/contentimages/footerbackground2.png');
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.about_section6_contentmain{
  height: 70%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px)
}
.about_section6_headermain{
  display: flex;
  justify-content: center;
}
.about_section6_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50rem;
}
.about_section6_hl{
  height: 0.2rem;
  width: 15rem;
  background-color: white;
}
.about_section6_content{
  display: flex;
  justify-content: space-around;
}
.about_section6_content_sub{
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* CONTACT US */
.contact_main{
  width: 100%;
  background-color: #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  padding: 150px 100px;
  gap:100px;
}
.contact_content{
  display: flex;
  justify-content: center;
  width: 100%;
}
.contact_form{
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 25px;
}
.contact_form_content{
  display: flex;
  flex-direction: column;
  gap:15px
}
.contact_form_content input, .contact_form_content textarea{
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  border:none
}
.contact_form_content button{
  padding: 3px 20px;
  width: fit-content;
  border: none;
  border-radius: 3px;
  background-color: #121212;
  color: #FFF;
  margin-top: 20px;
}
.contact_details{
  width: 40%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}
.contact_details_detail{
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 25px 20px;
  border-top: 2px solid white ;
  align-items: center;
}
.phonedetails{
  display: flex;
  gap:10px;
}
.contact_details_detail svg{
  width: 58px;
}
.contact_details_detail p, .contact_details_detail a{
  color: #FFF;
  font-size: 27px;
  margin: 0;
}
#contact_email_div{
  border-bottom: 2px solid white ;
}
.contact_map{
  width: 80%;
}
.contact_map iframe{
  width: 100%;
  height: 500px;
  border: 4px solid #000;
  border-radius: 5px;
}
#address{
  width: 500px;
}
/*
.contact_content_main{
  width: 80%;
}
.contact_headdiv{
  margin-top: 10rem;
}
.contact_content1_maindiv{
  display: flex;
  height: 18rem;
  justify-content: space-between;
}
.contact_content1{
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
  justify-content: space-between;
}
.contact_content1 input{
  width: 100%;
  height: 2rem;
}
.contact_content1  button{
  background-color: rgb(44, 41, 41);
  width: 4rem;
  border: 0px;
  color: white;
}
.contact_content2{
  width: 45%;
}
.contact_hl{
  width: 100%;
  height: 0.1rem;
  background-color: white;
}
.contact_content2_subdiv{
  display: flex;
  height: 7rem;
  align-items: center;
}
.contact_content2_subdiv p{
  margin-left: 2rem;
}
.contacticons{
  font-size: 1.5rem;
}
.contact_mapdiv{
  margin-top: 10rem;
  margin-bottom: 4rem;
} */


/* COLLECTION */
.collection_container{
  padding: 150px 100px;
}
.collection_main{
  display: flex;
  flex-direction: column;
  gap:20px;
}
.collection_container{
  background-color: #707070;
  color: white;
}
.collection_section{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap:25px;
  height: 650px;
}
.collection_img_container{
  height: 100%;
  display: flex;
  align-items: center;
  width: 65%;
  gap:15px;
}
.collection_img_container img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.collection_img_container .image1, .collection_img_container .image2{
  height: 80%;
  width: 50%;
}
.collection_img_container .image1{
  align-self: flex-start;
}

.collection_content{
  display: flex;
  flex-direction: column;
  width: 35%;
}
.collection_content p{
  font-size: 20px; 
}
.collection_content h4{
  font-size: 24px;
  font-weight: bold;
}
.collection_button{
  width: fit-content;
  border: none;
  padding:  5px 25px;
  border-radius: 3px;
}
.collection-line{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.collection-line hr{
  width: 100%;
  border: 2px solid #FFF;
}
.diamond-Icon1{
  rotate: -90deg;
}
.diamond-Icon2{
  rotate: 90deg;
}

/* 
@media screen and (max-width:1742px) {
  #home_collection1{
    background-image: url('./Assets/contentimages/home_collection1x1400.png');
  }
  #home_collection2{
    background-image: url('./Assets/contentimages/home_collection2x1400.png');
  }
  #home_collection3{
    background-image: url('./Assets/contentimages/home_collection3x1400.png');
  }
  #home_collection4{
    background-image: url('./Assets/contentimages/home_collection4x1400.png');
  }
  #home_collection5{
    background-image: url('./Assets/contentimages/home_collection5x1400.png');
  }
} */



@media screen and (max-width:1512px) {
  .collection_section{
    height:550px;
  }
  .section1_part2{
    height: 300px;
  }
  .about_section1_content_maindiv{
  }
  .about_section1_content_maindiv p{
    font-size: 20px;
  }
  .about_section5_contents{
    justify-content: center;
  }
  .product_view{
    padding: 120px 40px;
  }
  .contact_details_detail svg{
    width: 36px;
  }
  .contact_details_detail p, .contact_details_detail a{
    font-size: 22px;
  }
}
@media screen and (max-width:1500px) {
  .footer_content{
    order:2;
    width:100%;
    justify-content: center;
  }
}

@media screen and (max-width:1400px) {
  .section2_header{
    width: 80%;
  }
  /* .home_collection_text.text_right{
    margin-right: 2%;
  } */
}

@media screen and (max-width:1340px) {
  .categorylevel2icon{
    font-size: 34px;
  }
  .contact_form, .contact_details{
    width: 50%;
  }
  .contact_map{
    width: 100%;
  }
  .about_section1_content_maindiv p{
    line-height: 1.7;
  }
  /* #home_collection1{
    background-image: url('./Assets/contentimages/home_collection1x1500.png');
  }
  #home_collection2{
    background-image: url('./Assets/contentimages/home_collection2x1500.png');
  }
  #home_collection3{
    background-image: url('./Assets/contentimages/home_collection3x1500.png');
  }
  #home_collection4{
    background-image: url('./Assets/contentimages/home_collection4x1500.png');
  }
  #home_collection5{
    background-image: url('./Assets/contentimages/home_collection5x1500.png');
  } */
}

@media screen and (max-width:1100px) {
  .about_section5_certification_contents{
    justify-content: center;
  }
  .contact_form h4{
    font-size: 22px;
  }
  .category_item{
    width: 45%;
  }

}

@media screen and (max-width:1024px) {
  .section2_contents_content{
    flex-direction: column;
  }
  .section2_contents_content.rev-section{
    flex-direction: column-reverse;
  }
  .section2_contents_content{
    height: auto;
  }
  .section2_contents_content_text{
    width: 100%;
  }
  .section2_contents_content_image{
    width: 65%;
    align-self: flex-end;
  }
  .section2_contents_content.rev-section .section2_contents_content_image{
    align-self: flex-start;
  }
  .section3 .section2_line {
    width: 60%;
}

.section2_image{
  top:0;
  height: 100%;
}
#diamond1 {
  top: -10px;
  right: -1px;
  left: auto;
}
#diamond2{
  top:-10px;
  left: -15px;
}
.section2_image::after{
  width: 100%;
}
.about_bg_div::after{
  display: none;
}
.section2_image::before{
  display: none;
}
.about_section1_content_maindiv {
  padding-bottom: 50px;
}
.about_section1{
  gap:30px;
}
.about_bg_div {
  width: 60%;
}
.about_section4_content{
  flex-direction: column;
}
.about_section4_content2image{
  width: 100%;
  justify-content: flex-start;
}
.about_section4_content2text{
  padding: 0;
  width: 90%;
  align-self: center;
}
.section1_subsection_blurdiv h1{
  font-size: 32px;
}
.product_image_nav{
  position: relative;
  padding: 0 20px;
}
.collectionview_section1_submain{
  padding: 0;
}
.category_products_product h4{
  font-size: 22px;
}
.contact_details_detail p, .contact_details_detail a{
  font-size: 20px;
}
}

@media screen and (max-width:980px){
  .collection_section{
    flex-direction: column;
    height: fit-content;
    gap:0;
  }
  .collection_section.rev-section{
    flex-direction: column-reverse;
  }
  .collection_img_container{
    height: 500px;
    width: 100%;
  }
  .collection_content{
    width: 100%;
  }
  .footer_navdetails{
    flex-direction: column;
    order:0;
  }
  .footer_content{
    justify-content: space-around;
    align-items: flex-start;
  }
  .footer_contact{
    order:1;
  }
  .footer_address{
    order: 2;
  }
  .about_section5 .section2_line h4{
    padding: 0 50px;
  }
  .about_section5{
    padding: 50px 30px;
  }

  .category_section1{
    padding: 150px 80px;
  }
  .categorylevel1_section1{
    padding-bottom: 80px;
  }
  .category_products_product{
    flex: 1 1 47%;
  }
  .category_products{
    gap:3%;
  }
  .categorylevel2icon{
    font-size: 42px;
  }
  .category_level3_subdivmain{
    width: 80%;
  }
  .category_closebtn svg{
    font-size: 34px;
  }
  .contact_content{
    flex-direction: column;
  }
  .contact_form, .contact_details{
    width: 100%;
  }
  .contact_form_content{
    width: 100%;
  }
}


@media screen and (max-width:885px) {
  .home_image{ 
    background-position-y: 0;
  }
  .section1_subsection_blurdiv h1, .home_image1_content h1{
    font-size: 30px;
  }
  .section1_part2{
    height: 150px;
  }
  .section2_header h1{
    font-size: 35px;
  }
  .section6_items{
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .section6_item{
    width: 40%;
  }
  .section5_header h2{
    font-size: 30px;
    width: 65%;
  }
  .about_section1{
    flex-direction: column;
    align-items: center;
  }
  .about_bg_div{
    width: 100%;
    height: auto;
  }
  .about_section1_main, .about_section1{
    height: auto;
  }
  .about_section1_content_maindiv{
    width: 90%;
    margin: 0;
    padding: 0;
    padding-bottom: 25px;
  }
  .section2_image::after{
    width: 100%;
  }
  #diamond1{
    left: auto;
    right: 0;
  }
  .about_section5 .about_section5_certification .section2_line{
    width: 80%;
  }
  .blog_content_div h1{
    font-size: 40px;
  }
  .blog_content_div p{
    font-size: 20px;
  }
  .home_collection_text p{
    font-size: 18px;
  }
  .about_section2{
    flex-direction: column;
    gap:25px;
    height: fit-content;
    padding-bottom: 90px;
  }
  .about_section2_content{
    width: 80%;
    order: 1;
  }
  .section2_wrapper{
    width: 100%;
    order: 0;
    height: 400px;
  }
  #diamond2{
    left:0;
  }
  .about_section3_contentmaindiv{
    width: 500px;
    position: relative;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #707070;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
  }
}

@media screen and (max-width:768px) {
  .collection_container{
    padding: 100px 50px;
  }
  .section2_contents_content_image{
    width: 100%;
  } 
  .section1_subsection_image{
    flex: none;
    width: 50%;
  }
  .section1_subsection_contentdiv{
    flex-wrap: wrap;
    justify-content: center;
  }
  /* .home_image1_content{
    width: 80%;
  } */
  .section1_part2{
    height: 500px;
  }
  .section5_images{
    flex-wrap: wrap;
  }
  .section5_image{
    flex: none;
    width: 250px;
    height: 250px;
    object-fit: cover;
  }
  .section6_content h2{
    font-size: 30px;
  }
  .about_section2_item h2{
    font-size: 25px;
  }
  /* .about_section2_item{
    min-width: 410px;
  } */
  .about_section2_content{
    padding-left: 5%;
  }
  /* .section2_wrapper{
    width: auto;
  } */
  .section2_image{
    position: relative;
  }
  .about_section5 .about_section5_certification .section2_line h4{
    padding: 0 5%;
  }
  .nav_menubarsubdiv1{
    justify-content: space-between;
}
.menu_icondiv{
    display: block;
    padding-left: 2rem;
}
.nav_menu_div{
    display: none;
}
.logosec{
    display: none;
}
.logosec1{
    display: block;
}
.collectionview_section1_submain {
  aspect-ratio: 7/9;
}
.product_view{
  padding-top: 80px;
}
.category_section1{
  padding: 100px 40px;
}
.categorylevel1_section1{
  padding-top: 80px;
}
.categorylevel2icon{
  font-size: 36px;
}
.category_level3_subdivmain{
  width: 95%;
}
.contact_main{
  padding: 100px 0;
}
.contact_content, .contact_map{
  width: 87%;
}
.home_collection_text.text_right{
  right: 15px;
  align-items: flex-end;
  text-align:center;
}
.home_collection_text{
  left: 15px;
  width: 350px;
}
.home_collection_text p{
  width: 320px;
}
.home_collection_bg{
  height: 300px;
  object-fit: cover;
}
#home_collection1 .home_collection_bg, #home_collection3 .home_collection_bg, #home_collection5 .home_collection_bg{
  object-position: left;
}
#home_collection2 .home_collection_bg, #home_collection4 .home_collection_bg{
  object-position: right;
}
}

@media screen and (max-width:650px) {
  .section6_content{
    padding: 50px;
    width: 80%;
  }
  .section6{
    padding: 70px 0;
  }
  .section5_header h2{
    font-size: 25px;
    width: 65%;
  }
  .about_section5 .section2_line{
    width: 75%;
  }
  .about_section4_headdiv{
    width: 80%;
  }
  .product_image_nav button{
    width: 52px;
  }

  .categorylevel1_section1{
    padding: 80px 60px;
    gap:30px;
  }

  .home_collection_text{
    position: relative;
    left:0;
  }
  .home_collection{
    flex-direction: column;
    background: #707070;
    gap:15px;
    padding-bottom: 20px;
  }
  .home_collection_text.text_right{
    align-items: flex-start;
    text-align: start;
  }
}

@media screen and (max-width:620px) {

  /* .collection_img_container .image1, .collection_img_container .image2{
    height: 50%;
  } */
  .collection_section{
    gap:30px;
  }
  .home_image_contentdiv{
      margin-right: 0;
  }
  .home_image{
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 100px;
  }

  .section1_subsection_blurdiv h1{
    width: 90%;
  }
  .section1_subsection_container{
    width: 85%;
  }
  .section1_subsection_blurdiv h1, .home_image1_content h1{
    font-size: 25px;
  }
  .section3 .section2_line {
    width: 80%;
}
.section5_header{
  flex-direction: column;
  gap:10px;
}
.section5_header h2{
  width: 100%;
}
.section5_image{
  width: 200px;
  height: 200px;
}
.footer_content{
  flex-wrap: wrap;
  gap: 30px;
}
.about_section5 .about_section5_certification .section2_line h4{
  padding: 0 5%;
}
.about_section5 .about_section5_certification .section2_line{
  width: 100%;
}
.product_view{
  padding-bottom: 40px;
}
.category_level3_main{
  gap:10px;
}
.contact_form_content input, .contact_form_content textarea{
  width: 100%;
}
}

@media screen and (max-width:570px) {
  .footer_main{
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .footer_content{
    order:1;
  }
  .social_media_div{
    order:2;
    gap:10%;
  }
  .Category_Name_div h3{
    font-size: 27px;
  }
  .Category_Name_div select{
    font-size: 15px;
  }
  .home_image_contentdiv{
    width: 80%;
  }
  .home_image_contentdiv h2{
    font-size: 28px;
  }
}

@media screen and (max-width:510px) {
  .section1_subsection_image{
    width: 100%;
  }
  .section2{
    margin-top: 600px;
  }
  .section2_header, .section2_line, .section2_contents_content{
    width:90%;
  }
  .section2_header h1 {
    font-size: 30px;
}
.section6_item {
  width: 70%;
}
.section5_image {
  width: 100%;
  height: auto;
}
/* .home_collection_text, .home_collection_text.text_right{
  margin: 0;
  width: 80%;
} */
.home_collection_text{
  width:80%;
}
/* .home_collection_text.text_right{
  align-items:center;
} */
.home_collection_text p{
  width:100%;
}
.home_collection{
  justify-content: center;
}
.section6_content h2{
  font-size: 25px;
}
.about_section2_content{
  width: 95%;
}
/* .about_section2_item{
  min-width: 0;
  width: 90%;
} */
.about_section5 .about_section5_certification .diamond_line{
  display: none;
}
.about_section5 .about_section5_certification .section2_line{
  justify-content: center;
}
/* .about_section3{
  padding: 100px 50px;
} */
.about_section4_headdiv{
  width: 95%;
}
.about_section4_headdiv h4{
  padding: 0 10px;
}

.category_item{
  width: 100%;
}
.about_section3_contentmaindiv p{
  font-size: 18px;
}
/* .about_section2_content{
  width: 100%;
} */
#diamond2{
  left:-1px;
}
.blog_content_div{
  width: 100%;
}
.blog_content_div h1{
  font-size: 35px;
}
.blog_content_div p{
  font-size: 18px;
}
.home_video{
  flex-direction: column;
  width: 100%;
  height: fit-content;
}
.collection_img_container .image1, .collection_img_container .image2{
  width: 100%;
  height: 400px;
}
.collection_img_container{
  flex-direction: column;
  width: 100%;
  height: fit-content;
}
}




@media screen and (max-width:480px) {
  /* .collection_img_container .image1, .collection_img_container .image2{
    width: 60%;
  } */
  .section1_subsection_blurdiv h1, .home_image1_content h1 {
    font-size: 22px;
}
.categorylevel1_section1{
  gap:20px;
  padding-bottom: 50px;
}
.Category_Name_div{
  flex-direction: column;
  gap:15px;
}
.Category_Name_div select{
  align-self: flex-end;
}
.category_products_product{
  flex: none;
  width: 100%;
  align-items: center;
}
.categorylevel2icon{
  font-size: 38px;
}
.blog_content_div h1{
  font-size: 30px;
}
.blog_content_div p{
  font-size: 16px;
}
}

@media screen and (max-width:440px) {


  /* .home_image1_content {
    width: 95%;
} */
.section3 .section2_line h4{
  font-size: 22px;
}
.section5_header h2{
  font-size: 22px;
}
.section6_content{
  padding: 50px 30px;
}
.contact_content, .contact_map{
  width: 92%;
}
/* .about_section3{
  padding: 100px 25px;
} */

}


@media screen and (max-width:410px) {

  /* .collection_img_container{
    height: 500px;
  } */
  .section2 {
    margin-top: 420px;
  }
  .section2_contents_content h2{
    font-size: 27px;
  }
  .section2_contents_content_text p{
    font-size: 18px;
  }
  .section3 .section2_line {
    width: 95%;
}
.section6_content h2{
  font-size: 22px;
}
.contact_form h4{
  text-align: center;
}
.contact_details_detail{
  gap:20px;
}
}

@media screen and (max-width:390px) {
  .home_image1_content{
    padding:0 20px
  }
  .categorylevel2icon{
    font-size: 38px;
  }
  .contact_details_detail p, .contact_details_detail a{
    font-size: 18px;
  }
  .blog_content_div h1{
    font-size: 27px;
  }
}

@media screen and (max-width:364px) {
  .section1_part2 {
    height: 400px;
}
.section6_item {
  width: 85%;
}
.about_section4_headdiv .diamond_line{
  display: none;
}
.about_section4_headdiv .section2_line{
  justify-content: center;
}
.contact_details_detail p, .contact_details_detail a{
  font-size: 15px;
}
}


@media screen and (min-height: 950px) {
  .about_section1_content_maindiv{
    padding-bottom: 0;
  }
  .about_section1{
    align-items: center;
  }
}


@media screen and (max-height:790px) {
  .section1_subsection{
    top:-225px;
  }
}

/* @media screen and (min-height:700px) and (min-width:1025px) {
  .section2_image{
    top: -245px;
  }
  .section2_image::before{
    height: 245px;
  }
  #diamond1{
    top:-255px;
  }
  .section2_image{
    height: calc(100% + 245px);
  }
} */

@media screen and (max-height:790px) and (max-width:1512px) {
  .section1_part2 {
    height: 400px;
}
}

@media screen and (max-height:790px) and (max-width:885px) {
  .section1_part2 {
    height: 300px;
}
}

@media screen and (max-height:790px) and (max-width:768px) {
  .section1_part2 {
    height: 600px;
}
}

@media screen and (max-height:790px) and (max-width:510px) {
  .section2 {
    margin-top: 620px;
}
}

@media screen and (max-height:790px) and (max-width:410px) {
  .section2 {
    margin-top: 420px;
}
}

@media screen and (max-height:790px) and (min-width:1025px) {
  .section2_image{
    top:-205px;
    height: calc(100% + 205px);
  }
  .section2_image::before{
    height: 205px;
  }
  #diamond1{
    top:-215px;
  }
  .about_section1_content_maindiv{
    padding-bottom: 225px;
  }
}

@media screen and (max-height:745px) and (max-width:1600px) {
  .about_section1_content_maindiv p {
    line-height: 1.7;
}
}

@media screen and (max-height:745px) and (max-width:1200px) {
  .about_section1_content_maindiv p {
    line-height: 1.5;
}
}
@media screen and (max-height:745px) and (max-width:885px) {
  .about_section1_content_maindiv p {
    line-height: 2;
}
}

@media screen and (max-height:700px) and (max-width:1760px) {
  .about_section1_content_maindiv p {
    line-height: 1.7;
}
}
@media screen and (max-height:700px) and (max-width:1200px) {
  .about_section1_content_maindiv p {
    font-size: 18px;
}
}
@media screen and (max-height:700px) and (max-width:885px) {
  .about_section1_content_maindiv p {
    font-size: 20px;
    line-height: 2;
}
}
@media screen and (max-height:680px) and (min-width:1025px) {
  .section2_image{
    top: -155px;
    height: calc(100% + 155px);
  }
  #diamond1{
    top: -165px;
  }
  .section2_image::before{
    height: 155px;
  }
  .about_section1_content_maindiv {
    padding-bottom: 175px;
}
}
/* @media screen and (max-height:700px) and (min-width:1025px) {
  .section2_image{
    top: -215px;
    height: calc(100% + 215px);
  }
  #diamond1{
    top: -225px;
  }
  .section2_image::before{
    height: 215px;
  }
} */

@media screen and (max-height:600px) and (min-width:1025px) {
  .section2_image{
    top: -115px;
    height: calc(100% + 115px);
  }
  #diamond1{
    top: -125px;
  }
  .section2_image::before{
    height: 115px;
  }
  .about_section1_content_maindiv{
    margin-bottom: 0;
  }
}
@media screen and (max-height:600px) and (max-width:1024px) {
  .about_section1_content_maindiv{
    margin-top: 10%;
  }
}

@media screen and (max-height:600px) and (max-width:885px) {
  .about_section1_content_maindiv{
    margin-top: 0;
  }
}

/* .collection_section1_sub1main{
  display: flex;
  flex-direction: column;
  width: fit-content;
}  
.collection_section1_sub1content_main{
  display: flex;
  gap: 15px;
}
.collection_section1_sub1content_main div{
  height: 19rem;
  width: 15rem;
  background-color: #B0B0B0;
}
.collection_section1_sub1content_main img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.collection_section1_sub1content2{
  margin-top: 2rem;
}
.collection_section1_sub2main{
  display: flex;
  flex-direction: column;
}

.collection_button{
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: white;
  color: black;
  border: 0;
  width: fit-content;
}
.collectionhl{
  width: 100%;
  height: 0.1rem;
  background-color: white;
  position: relative;
}
.collectionhl_icon{
  transform: rotate(-90deg);
  position: absolute;
  left: -0.1rem;
  bottom: -0.49rem;
}
.collectionhl_icon2{
  transform: rotate(-270deg);
  position: absolute;
  right: -0.5rem;
  bottom: -0.46rem;
} */
/* .collection_section2_sub1main{
  display: flex;
  flex-direction: column;
}
.collection_section2_sub2main{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.collection_section2_sub2main p{
  width: 25rem;
}
.collection_section2_sub2main button{
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: white;
  color: black;
  border: 0;
  width: fit-content;
}

 */



/* COLLECTION VIEW PRODUCT */


@media only screen and (max-width:1080px) {
  .blog{
    padding: 150px 50px;
  }
  .blog_img_container {
    width: 40%; 
    height: 80%;
  }  
  .blog_content {
    width: 60%;
  }

}

@media only screen and (max-width:880px) {
  .blog_content_main{
    flex-direction: column;
    height: auto;
  }
  .blog_img_container, .blog_content{
    width: 100%;
  }
  #blog2{
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width:680px) {
  .blog{
    padding: 80px 30px;
  }
}
/*
.blog__content2_main{
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  paddipxng-top: 7rem;
  padding-bottom: 7rem;
}
.blog__content3_main{
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.blog__content1_main{
  display: flex;
  flex-direction: row;
  gap:20px;
  max-width: 1200px;
  align-items: center;
}
.blog__content1_sub1{
  width: 40%;
  background-color: white;
  height: 350px;
}
.blog__content1_sub1 img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.blog__content1_sub2 {
  width: 60%;
}
.blog__content1_sub2 p{
  width: 100%;
}
.blog__content1_sub2 h2{
  width: 100%;
}

.bloglevel1_container{
  color: white;
  padding-top: 10rem;
  padding-left: 7rem;
  padding-right: 7rem;
  padding-bottom: 7rem;
}
.blog_level1_contentdiv{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
}
.blog_level1_contentdiv div{
  width: 100%;
}
.blog_level1_contentdiv img{
  width: 100%;
  /* height: 80vh; 
}
.blog_level1_contentdiv p{
  width: 70%
}
 */
